<template>
  <div>
    <b-row>
      <b-col>
        <div class="cat-grid">
          <b-card
            v-for="(card, index) in actions"
            :key="index"
            v-if="card.condition !== false"
            @click="card.onClick"
            class="cat"
          >
            <div
              class="
                h-100
                d-flex
                flex-column
                justify-content-between
                align-items-center
              "
            >
              <div class="mb-2 name text-center">{{ card.name }}</div>
              <i :class="`${card.icon} fa-3x mb-50`"></i>
            </div>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "underscore"

export default {
  data() {
    const userData = JSON.parse(localStorage.getItem("userData"))

    return {
      userData,

      actions: [
        {
          name: "Ver inventario",
          icon: "fas fa-list",
          condition:
            userData.role_name === "admin" ||
            userData.role_name === "establishment_admin" ||
            userData.role_name === "establishment_supplier",
          onClick: () =>
            this.$router.push({
              name: "store-products",
              params: { id: this.$route.params.id },
            }),
        },
        {
          name: "Agregar producto o servicio",
          icon: "fas fa-plus",
          condition:
            userData.role_name === "admin" ||
            userData.role_name === "establishment_admin" ||
            userData.role_name === "establishment_supplier",
          onClick: () =>
            this.$router.push({
              name: "add-store-products",
              params: { id: this.$route.params.id },
            }),
        },
        {
          name: "Promos",
          icon: "fas fa-gift",
          condition:
            userData.role_name === "admin" ||
            userData.role_name === "establishment_admin" ||
            userData.role_name === "establishment_supplier",
          onClick: () =>
            this.$router.push({
              name: "get-store-promotions",
              params: { id: this.$route.params.id },
            }),
        },
        {
          name: "Ver variantes",
          icon: "fas fa-list",
          condition:
            userData.role_name === "admin" ||
            userData.role_name === "establishment_admin" ||
            userData.role_name === "establishment_supplier",
          onClick: () =>
            this.$router.push({
              name: "store-variants",
              params: { id: this.$route.params.id },
            }),
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/core/colors/palette-variables.scss";

.fixed-text {
  font-size: 13px !important;
  color: $primary !important;
}

.cat-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  grid-row: 1fr;
  font-weight: 600;
  font-size: 1rem;

  .cat {
    margin-bottom: 0;
    cursor: pointer;
    max-height: 160px;
    height: 160px;
  }

  @media (min-width: 350px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 700px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 1536px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (min-width: 1650px) {
    grid-template-columns: repeat(8, 1fr);
  }

  .icon {
    width: 50px;
    height: 50px;
  }
}
</style>
